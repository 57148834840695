'use client';
import React, { Fragment, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { InventoryContext } from '@/contexts/InventoryContext';
import { InventoryFiltersContext } from '@/contexts/InventoryFiltersContext';
import { InventoryTypeMap } from '@/types/inventory';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonType, FilterToggle } from '@lamesarv-sdk/components';
import { useCustomSearchParams, useDebounce } from '@lamesarv-sdk/hooks';
import {
  ClearButton,
  FilterCheckboxAdvanced,
  FilterCheckboxSimple,
  FiltersCurrentList,
  FilterSlider,
  SearchButton,
} from '@lamesarv-sdk/inventory';
import { filtersTitles, IFacets, IMetaData, InventoryField } from '@lamesarv-sdk/types';

import { FlashSaleBadge } from './Badges/FlashSaleBadge';
import { SpecialOfferBadge } from './Badges/SpecialOfferBadge';
import { FilterCheckboxBodies } from './Filters/CheckboxBodies';
import { FilterCheckboxEquipment } from './Filters/CheckboxEquipment';
import { FilterCheckboxSlideouts } from './Filters/CheckboxSlideouts';

const SearchBox = () => {
  const { setSearchParam, removeSearchParam } = useCustomSearchParams();

  const [onSearch] = useDebounce((value: string) => {
    if (value) setSearchParam('q', value);
    else removeSearchParam('q');
  });

  return (
    <div className="relative">
      <div className="absolute h-full left-0 top-0 flex items-center pl-3">
        <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
      </div>
      <input
        className="w-full pl-9 py-1 md:py-2"
        placeholder="Search..."
        onChange={(e) => onSearch(e.target.value)}
        data-testid="search-input"
      />
    </div>
  );
};

const Section = (
  props: PropsWithChildren<{
    attribute: InventoryField;
    title?: string;
    isOpened?: boolean;
    extraClasses?: string;
  }>,
) => {
  const { context } = useContext(InventoryContext);
  const { filtersToHide } = useContext(InventoryFiltersContext);

  const hasFilters = !!context.inventoryFilters?.[props.attribute]?.facetValue?.length;

  const [isOpened, setIsOpened] = useState<boolean>(props.isOpened || hasFilters);

  useEffect(() => {
    setIsOpened(props.isOpened || hasFilters);
  }, [hasFilters, props.isOpened]);

  if (filtersToHide.has(props.attribute)) return null;

  return (
    <div
      className="sidebar-section group border-b border-gray-300 last:border-none w-full"
      data-testid={`${props.title}-filter-box`}
    >
      <div
        className={
          'sidebar-section-title flex flex-row py-4 px-4 group-hover:bg-gray-100 text-left text-sm font-medium cursor-pointer'
        }
        onClick={() => {
          setIsOpened(!isOpened);
        }}
        data-testid={`${props.title}-toggle-${isOpened ? 'open' : 'closed'}`}
      >
        <span className="flex-1 font-semibold text-gray-500 uppercase">
          <span data-testid="filter-title">{props.title}</span>
        </span>
        <span className="flex-shrink">
          {isOpened && <FontAwesomeIcon icon={faChevronUp} className="w-5" />}
          {!isOpened && <FontAwesomeIcon icon={faChevronDown} className="w-5" />}
        </span>
      </div>
      <div className={`sidebar-section-content group-hover:bg-gray-100 p-2 pt-0 ${isOpened ? 'block' : 'hidden'}`}>
        {props.children}
      </div>
    </div>
  );
};

const formatPriceFilter = (value?: number) =>
  value !== undefined ? (Math.floor(value / 100) / 10).toFixed(1) + 'K' : '';

interface CurrentFiltersWithClearProps {
  simpleView?: boolean;
}

export const CurrentFiltersWithClear = ({ simpleView }: CurrentFiltersWithClearProps) => {
  const { context } = useContext(InventoryContext);
  const { callbackClearFilters, callbackApplyFilter } = useContext(InventoryFiltersContext);

  if (simpleView && Object.keys(context.inventoryFilters || {}).length === 0 && !context.inventoryQuery) return null;

  return (
    <div className="block bg-white" data-testid={`${simpleView ? 'menu' : 'main'}-filters-view`}>
      {!simpleView && (
        <ClearButton
          query={context.inventoryQuery}
          filters={context.inventoryFilters}
          buttonType={ButtonType.basic}
          icon={<Fragment />}
          className={twMerge(
            'w-full rounded-b-none justify-center',
            'text-sm text-white uppercase bg-sage-700 bg-gradient-to-b from-sage-500 to-sage-700 shadow-md hover:bg-sage-700 hover:from-sage-900 hover:to-sage-600 hover:shadow-lg',
          )}
          classTitle="text-center"
          callbackClearFilters={callbackClearFilters}
        />
      )}
      <FiltersCurrentList
        query={context.inventoryQuery}
        filters={context.inventoryFilters}
        filterLabels={InventoryTypeMap}
        callbackApplyFilter={callbackApplyFilter}
        className="bg-neutral-200 "
        classIcon="h-3 text-sage-600"
        classTitle="text-sage-600"
        customRangeNumberFormatters={{
          [InventoryField.price]: formatPriceFilter,
        }}
      />
      {simpleView && (
        <button
          onClick={callbackClearFilters}
          type="button"
          className="underline text-sage-800 mb-1 mt-4 ml-4"
          data-testid="clear-filters-txt"
        >
          CLEAR FILTERS
        </button>
      )}
    </div>
  );
};

export interface InventoryFiltersProps {
  customGroup?: string;
  metadata: IMetaData;
  hideMobileSearchButton?: boolean;
  isMobile?: boolean;
  filtersToHide?: Set<string>;
}

export const InventoryFilters = (props: InventoryFiltersProps) => {
  const { context, mapFilters, filtersToUrl } = useContext(InventoryContext);
  const { callbackApplyFilter } = useContext(InventoryFiltersContext);

  const filters = useMemo(() => {
    if (!context.inventoryFiltersBase || !context.inventoryFilters) return {};

    return mapFilters(context.inventoryFiltersBase as IFacets, context.inventoryFilters);
  }, [context.inventoryFiltersBase, context.inventoryFilters]);

  const years = filters?.[InventoryField.modelYear] ? Object.keys(filters[InventoryField.modelYear]).map(Number) : null;
  const minYear = years ? Math.min(...years) : 2015;
  const maxYear = years ? Math.max(...years) : new Date().getFullYear() + 1;

  const prices = filters?.[InventoryField.price] ? Object.keys(filters[InventoryField.price]).map(Number) : null;
  const maxPrice = prices ? Math.max(...prices) : 1000000;

  return (
    <div className="flex flex-col flex-1">
      {!props.hideMobileSearchButton && (
        <div className="md:hidden mt-4">
          <SearchButton
            query={context.inventoryQuery}
            filters={context.inventoryFilters}
            filtersToUrl={filtersToUrl}
            title="VIEW RESULTS"
          />
        </div>
      )}
      <CurrentFiltersWithClear simpleView={props.isMobile} />
      <div className="mt-3">
        <SearchBox />
      </div>
      <div className="hidden md:block md:my-5">
        <SearchButton query={context.inventoryQuery} filters={context.inventoryFilters} filtersToUrl={filtersToUrl} />
      </div>
      <div className="overflow-auto flex-1">
        <Section title="PROMOTIONS" attribute={InventoryField.special} isOpened>
          <FilterToggle
            attribute={InventoryField.special}
            items={filters[InventoryField.special]}
            title={<SpecialOfferBadge />}
            callBackSelection={callbackApplyFilter}
          />
          <FilterToggle
            attribute={InventoryField.inFlashSale}
            items={filters[InventoryField.inFlashSale]}
            title={<FlashSaleBadge />}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
        <Section
          title={filtersTitles[InventoryField.inventoryType]}
          attribute={InventoryField.inventoryType}
          isOpened={!props.isMobile}
        >
          <FilterCheckboxSimple
            attribute={InventoryField.inventoryType}
            items={filters[InventoryField.inventoryType]}
            callBackSelection={callbackApplyFilter}
            itemsLabelMap={InventoryTypeMap}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.body]} attribute={InventoryField.body} isOpened={!props.isMobile}>
          <FilterCheckboxBodies
            attribute={InventoryField.body}
            items={filters[InventoryField.body]}
            metadata={props.metadata}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
        <Section
          title={filtersTitles[InventoryField.fuelType]}
          attribute={InventoryField.fuelType}
          isOpened={!props.isMobile}
        >
          <FilterCheckboxSimple
            attribute={InventoryField.fuelType}
            items={filters[InventoryField.fuelType]}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.make]} attribute={InventoryField.make}>
          <FilterCheckboxAdvanced
            attribute={InventoryField.make}
            items={filters[InventoryField.make]}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.model]} attribute={InventoryField.model}>
          <FilterCheckboxAdvanced
            attribute={InventoryField.model}
            items={filters[InventoryField.model]}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.modelYear]} attribute={InventoryField.modelYear}>
          <FilterSlider
            attribute={InventoryField.modelYear}
            displayMin={minYear}
            displayMax={maxYear}
            items={filters[InventoryField.modelYear]}
            callBackSelection={callbackApplyFilter}
            customFormat={(value) => value?.toString() || ''}
            value={context.inventoryFilters?.[InventoryField.modelYear]?.facetValue}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.modelNumber]} attribute={InventoryField.modelNumber}>
          <FilterCheckboxAdvanced
            attribute={InventoryField.modelNumber}
            items={filters[InventoryField.modelNumber]}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.length]} attribute={InventoryField.length}>
          <FilterSlider
            attribute={InventoryField.length}
            suffix="ft."
            displayMin={0}
            displayMax={50}
            items={filters[InventoryField.length]}
            callBackSelection={callbackApplyFilter}
            value={context.inventoryFilters?.[InventoryField.length]?.facetValue}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.price]} attribute={InventoryField.price}>
          <FilterSlider
            attribute={InventoryField.price}
            prefix="$"
            displayMin={0}
            displayMax={maxPrice}
            items={filters[InventoryField.price]}
            callBackSelection={callbackApplyFilter}
            customFormat={formatPriceFilter}
            value={context.inventoryFilters?.[InventoryField.price]?.facetValue}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.equipment]} attribute={InventoryField.equipment}>
          <FilterCheckboxEquipment
            attribute={InventoryField.equipment}
            items={filters[InventoryField.equipment]}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.slideOutsCount]} attribute={InventoryField.slideOutsCount}>
          <FilterCheckboxSlideouts
            attribute={InventoryField.slideOutsCount}
            displayMax={5}
            displaySearch={false}
            displayShowMore={false}
            items={filters[InventoryField.slideOutsCount]}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
        <Section title={filtersTitles[InventoryField.location]} attribute={InventoryField.location}>
          <FilterCheckboxAdvanced
            attribute={InventoryField.location}
            items={filters[InventoryField.location]}
            callBackSelection={callbackApplyFilter}
          />
        </Section>
      </div>
      <div className="hidden md:block md:mt-5">
        <SearchButton query={context.inventoryQuery} filters={context.inventoryFilters} filtersToUrl={filtersToUrl} />
      </div>
    </div>
  );
};
